import { Heading } from "@Components";
import type React from "react";

interface PageSectionProps {
	title: string;
}

export const PageSection: React.FC<
	React.PropsWithChildren<PageSectionProps>
> = ({ title, children }) => (
	<section>
		<Heading
			as={"h2"}
			variant={["large", "xlarge"]}
			sx={{
				textAlign: "center",
				marginBottom: "xl",
			}}
		>
			{title}
		</Heading>
		{children}
	</section>
);
