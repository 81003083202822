import {
	DOMInteractionEvent,
	Heading,
	Link,
	Paragraph,
	useTrackingContext,
} from "@Components";
import { type Illustrations, svgFilenames, viewBoxes } from "@Tokens";
import { useTranslation } from "@loveholidays/phrasebook";
import { useCallback } from "react";
import type { SxStyleProp } from "theme-ui";
import { pictogramStyles } from "./pictogramStyles";

type UspsComponentType = {
	usps: {
		title: string;
		description: string | null;
		illustration: string;
		link: {
			href: string;
			rel: ("nofollow" | "noopener" | "noreferrer")[] | null;
			target: string | null;
		} | null;
		iconBackgroundColor: string | null;
	}[];
};

const ThreeByThreeConfig = (): SxStyleProp => ({
	gridTemplateColumns: ["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"],
});

const TwoByTwoConfig = (): SxStyleProp => ({
	gridTemplateColumns: ["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(4, 1fr)"],
});

export const UspsComponent: React.FC<UspsComponentType> = ({ usps }) => {
	const { t } = useTranslation();

	if (!usps?.length) {
		return null;
	}

	const isOddOrByThree = usps.length % 3 === 0 || usps.length % 2 === 1;

	return (
		<section data-id="usps">
			<Heading
				as="h2"
				variant="largebold"
				sx={{
					marginBottom: "2xl",
				}}
			>
				{t("bookWithConfidence")}
			</Heading>

			<div
				sx={{
					display: "grid",
					rowGap: "2xl",
					columnGap: "s",
					...(isOddOrByThree ? ThreeByThreeConfig() : TwoByTwoConfig()),
				}}
			>
				{usps.map((usp) => (
					<Usp {...usp} key={usp.title} />
				))}
			</div>
		</section>
	);
};

const UspStyles: SxStyleProp = {
	display: "block",
	textDecoration: "none",
	color: "iconDimmedheavy",
	position: "relative",
	"&:hover, :visited": {
		color: "iconDimmedheavy",
	},
};

const UspContent: React.FC<Omit<UspsComponentType["usps"][number], "link">> = ({
	description,
	title,
	illustration,
	iconBackgroundColor,
}) => {
	const iconColor = "backgroundLightsubtle";
	const src = svgFilenames[`Illustration/${illustration as Illustrations}`];
	const viewbox = viewBoxes[`Illustration/${illustration as Illustrations}`];

	return (
		<div
			sx={{
				display: "flex",
				textAlign: "left",
				alignItems: "center",
			}}
		>
			<svg
				role="img"
				aria-label={title}
				viewBox={`0 0 ${viewbox?.[0]} ${viewbox?.[1]}`}
				sx={{
					...pictogramStyles({
						size: ["64"],
						backgroundColor: iconBackgroundColor ? iconColor : undefined,
					}),
					color: "iconDefault",
					marginRight: "2xs",
					marginBottom: 0,
				}}
			>
				<use href={src} />
			</svg>
			<div>
				<Heading variant="smallbold" as="h3" sx={{ marginBottom: "5xs" }}>
					{title}
				</Heading>
				{description && (
					<Paragraph variant="extrasmall" sx={{ color: "iconDimmedheavy" }}>
						{description}
					</Paragraph>
				)}
			</div>
		</div>
	);
};

const Usp: React.FC<UspsComponentType["usps"][number]> = ({ link, ...usp }) => {
	const { trackEvent } = useTrackingContext();
	const trackClick = useCallback(
		() => trackEvent(DOMInteractionEvent.CLICK, "usp"),
		[trackEvent],
	);
	return link ? (
		<Link
			sx={UspStyles}
			href={link.href}
			target={link.target ?? undefined}
			rel={link.rel?.join(" ")}
			onClick={trackClick}
		>
			<UspContent {...usp} />
		</Link>
	) : (
		<div sx={UspStyles}>
			<UspContent {...usp} />
		</div>
	);
};
