import type React from "react";
import type { HTMLAttributes } from "react";

import { useIsSelected } from "../SunriseUISelectable";

interface SunriseUICollapseProps extends HTMLAttributes<HTMLDivElement> {
	id: string;
	selected?: boolean;
	lazy?: boolean;
	children: React.ReactNode;
}

// displays nothing when aria-hidden is set and supports as prop
const Hideable: React.FC<HTMLAttributes<HTMLDivElement>> = ({
	children,
	...rest
}) => (
	<div
		sx={{
			'&[aria-hidden="true"]': {
				display: "none",
			},
		}}
		{...rest}
	>
		{children}
	</div>
);

const Collapse: React.FC<SunriseUICollapseProps> = ({
	children,
	id,
	selected,
	lazy,
	...props
}) => (
	<Hideable id={id} aria-hidden={!selected} {...props}>
		{lazy && !selected ? null : children}
	</Hideable>
);

export const SelectableCollapse: React.FC<
	Omit<SunriseUICollapseProps, "selected">
> = ({ children, id, ...props }) => {
	const isSelected = useIsSelected(id);

	return (
		<Collapse id={id} selected={isSelected} {...props}>
			{children}
		</Collapse>
	);
};
