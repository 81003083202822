import type React from "react";

import { Heading, Link, List } from "@Components";
import { useTranslation } from "@loveholidays/phrasebook";

type HotelLink = {
	children?: {
		count: number;
		totalCount: number;
		items: HotelLink[];
	};
	fullUrl?: string;
	icon?: string;
	label: string;
	logo?: string;
	rel?: string;
	target?: string;
	url: string | null;
};

export type HotelLinksComponentProps = {
	hotelLinks: {
		hotelsByRating: {
			items: {
				hotels: HotelLink[];
				rating: number;
			}[];
		};
		subtitleText: string;
		title: string;
	};
};

export const HotelLinksComponent: React.FC<HotelLinksComponentProps> = ({
	hotelLinks: { hotelsByRating, subtitleText, title },
}) => {
	const { t } = useTranslation();

	return (
		<>
			<Heading
				as="h2"
				variant={["large", "xlarge"]}
				sx={{
					textAlign: "center",
					marginBottom: "xl",
				}}
			>
				{title}
			</Heading>

			{subtitleText && (
				<div sx={{ marginBottom: "3xs", textAlign: "center" }}>
					{subtitleText}
				</div>
			)}

			{hotelsByRating.items.map(
				(rating, idx) =>
					!!rating && (
						<section
							key={`rating_hotels_${rating.hotels?.length}_${idx}`}
							sx={{
								borderTop: (t) => `1px solid ${t.colors.strokeLightneutral}`,
								marginTop: "3xs",
								paddingTop: "xs",
							}}
						>
							<Heading
								as="h3"
								variant="medium"
								sx={{
									marginBottom: "3xs",
								}}
							>
								{t("hotelLinks.nStarHotels", {
									count: rating.rating,
								})}
							</Heading>
							<List variant="chevron" columns={[1, 2, 3]}>
								{rating.hotels.map(
									(link) =>
										!!link?.url && (
											<li key={link.url}>
												<Link href={link.url}>{link.label}</Link>
											</li>
										),
								)}
							</List>
						</section>
					),
			)}
		</>
	);
};
