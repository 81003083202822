import type React from "react";

import { DiscountMerchandisingMessagingTooltip } from "./DiscountMerchandisingSash";
import type { Pricing } from "./type";

interface DiscountTooltipProps {
	pricing: Pricing;
}

export const DiscountTooltip: React.FC<DiscountTooltipProps> = ({
	pricing: { pricingPresentation, discountPresentation },
}) => {
	if (
		discountPresentation.type === "SUPPLIER" &&
		discountPresentation?.tooltipText
	) {
		return (
			<DiscountMerchandisingMessagingTooltip
				copy={discountPresentation?.tooltipText}
			/>
		);
	}

	if (pricingPresentation?.tooltipText) {
		return (
			<DiscountMerchandisingMessagingTooltip
				copy={pricingPresentation?.tooltipText}
			/>
		);
	}

	return null;
};
