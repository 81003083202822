import { hastToReact } from "@AuxiliaryComponents";
import {
	Heading,
	Image,
	Label,
	Link,
	Paragraph,
	type TrackingObject,
	lineClamp,
	mediaBrick,
	useTrackingContext,
} from "@Components";
import { useInViewTracking } from "@Hooks";
import { CarouselRenderProp } from "@Modules";
import { useTranslation } from "@loveholidays/phrasebook";
import { useCallback, useMemo } from "react";
import type { HastNode } from "./HastNode";

interface DestinationCollectionComponentProps {
	destinationCollection: {
		title: string;
		description: HastNode | null;
		destinations: {
			siteRelativeHref: string;
			image: {
				url: string;
				description: string;
			};
			name: string;
			description: string;
			pricePerPerson: number;
			displayPricePerPerson: string;
			id: string | null;
		}[];
	};
}

const DestinationCollectionCard: React.FC<{
	destination: DestinationCollectionComponentProps["destinationCollection"]["destinations"][number];
	index: number;
}> = ({
	destination: {
		siteRelativeHref,
		name,
		description,
		displayPricePerPerson,
		pricePerPerson,
		id,
		image,
	},
	index,
}) => {
	const { t } = useTranslation();
	const { sendEvent } = useTrackingContext();
	const trackingPayload = useMemo<
		TrackingObject & { event: "Product List Viewed" }
	>(
		() => ({
			event: "Product List Viewed",
			payload: {
				masterId: id,
				name,
				position: index + 1,
				price: pricePerPerson,
				list: "DestinationCollectionCard",
			},
		}),
		[id, name, index, pricePerPerson],
	);

	const onClick = useCallback(
		() =>
			sendEvent({
				...trackingPayload,
				event: "Product Click",
			}),
		[sendEvent, trackingPayload],
	);

	const impressionRef = useInViewTracking(trackingPayload, false);
	return (
		<Link
			ref={impressionRef}
			href={siteRelativeHref}
			sx={{
				color: "textDefault",
				"&,&:hover,&:visited": {
					textDecoration: "none",
					color: "textDefault",
				},
				display: "flex",
				backgroundColor: "white",
				borderRadius: 12,
				borderColor: "strokeLightneutral",
				borderWidth: "outlinedStrokeWeight",
				borderStyle: "solid",
				overflow: "hidden",
				flexDirection: "column",
				"&:hover": {
					boxShadow: "elevationHover",
				},
				height: "100%",
			}}
			data-id="collectionDestination-card"
			onClick={onClick}
		>
			<Image
				src={image?.url}
				alt={image?.description}
				width={225}
				height={175}
			/>
			<div
				sx={{
					padding: "s",
					height: "100%",
					...mediaBrick({
						padding: "2xs",
					}),
					display: "flex",
					flexDirection: "column",
					gap: "3xs",
				}}
			>
				<Heading as="h3" variant="mediumbold">
					{name}
				</Heading>
				{!!description && (
					<Paragraph
						variant="extrasmall"
						sx={{
							color: "textDimmedheavy",
							...lineClamp(3),
							flexGrow: 1,
						}}
					>
						{description}
					</Paragraph>
				)}
				<p sx={{ fontSize: "m", lineHeight: "normal" }}>
					<Label variant="medium">{t("priceQuoteUnit.from")}</Label>
					<Label
						variant="extralargebold"
						sx={{
							marginLeft: "3xs",
							marginRight: "4xs",
						}}
					>
						{displayPricePerPerson}
					</Label>
					<Label variant="mediumbold">
						{t("priceQuoteUnit.perPersonShort")}
					</Label>
				</p>
			</div>
		</Link>
	);
};

export const DestinationCollectionComponent: React.FC<
	DestinationCollectionComponentProps
> = ({ destinationCollection: { description, title, destinations } }) => {
	return (
		<CarouselRenderProp
			sx={{
				marginX: [0, "-xs"],
				scrollPaddingLeft: [0, "xs"],
				scrollPaddingRight: [0, "xs"],
				paddingX: [0, "xs"],
				paddingBottom: "xs",
			}}
			render={({ carousel, scrollButtons }) => (
				<section
					sx={{ marginY: ["2xl", null, "3xl"] }}
					data-id="DestinationCollection"
				>
					<div
						sx={{
							display: "flex",
							justifyContent: "space-between",
							marginBottom: "s",
							alignItems: description ? "flex-end" : "baseline",
						}}
					>
						<div>
							<Heading
								variant="largebold"
								as="h2"
								sx={{
									...(!!description && {
										marginBottom: "xs",
									}),
								}}
							>
								{title}
							</Heading>
							{!!description &&
								hastToReact(description, {
									p: {
										Component: Paragraph,
										Props: {
											variant: "medium",
											sx: {
												marginRight: "l",
											},
										},
									},
									a: {
										Component: Link,
									},
								})}
						</div>
						{scrollButtons}
					</div>
					{carousel}
				</section>
			)}
		>
			{destinations.map((destination, index) => (
				<DestinationCollectionCard
					destination={destination}
					index={index}
					key={`${index}-${destination.id}`}
				/>
			))}
		</CarouselRenderProp>
	);
};
