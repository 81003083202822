import { Carousel, disabledCarouselStyles } from "@Modules";
import type React from "react";

import { PageContainer } from "@Components";
import type { Icons, LegacyIcons } from "@Tokens";
import { ContentBox } from "./ContentBox";

type HolidayLink = {
	icon: Icons;
	title: string;
	url: string;
};

export interface HolidayLinksProps {
	holidayLinks: HolidayLink[];
}

export const HolidayLinksComponent: React.FC<HolidayLinksProps> = ({
	holidayLinks,
}) => {
	if (!holidayLinks?.length) {
		return null;
	}

	return (
		<section
			data-id="holiday-links"
			sx={{
				backgroundColor: "backgroundLightsubtle",
				paddingTop: "l",
				paddingBottom: "l",
			}}
		>
			<PageContainer>
				<Carousel
					maxItemsOnShow={[1.3, 2, 4]}
					sx={disabledCarouselStyles([1, 2, 4], [4, 2, 1])}
				>
					{holidayLinks.map(({ icon, title, url }) => (
						<ContentBox
							key={url}
							icon={icon as LegacyIcons}
							title={title}
							url={url}
						/>
					))}
				</Carousel>
			</PageContainer>
		</section>
	);
};
