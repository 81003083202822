import { Image } from "@Components";
import { Carousel } from "@Modules";
import { useTranslation } from "@loveholidays/phrasebook";
import type React from "react";
import { PageSection } from "./PageSection";

interface WeatherComponentProps {
	weatherSection: {
		image: {
			url: string;
			description: string;
		};
		title: string;
		weather: {
			averageDryDays: number;
			averageTemperature: number;
			highlighted: boolean;
			month: string;
		}[];
	};
}

export const WeatherSectionComponent: React.FC<WeatherComponentProps> = ({
	weatherSection: { title, image, weather },
}) => {
	const { t } = useTranslation();

	return (
		<PageSection title={title} data-id="weather-section">
			<div
				sx={{
					position: "relative",
					minHeight: "300px",
				}}
			>
				<Image
					alt={image.description}
					src={image.url}
					sx={{
						position: "absolute",
						width: "100%",
						height: "100%",
						objectFit: "cover",
					}}
					width={[400, 1200, 2000]}
				/>
				<div
					sx={{
						position: "absolute",
						bottom: 0,
						width: "100%",
						height: "100%",
						display: "flex",
						alignItems: "flex-end",
					}}
				>
					<div
						sx={{
							position: "relative",
							width: "inherit",
						}}
					>
						<Carousel
							maxItemsOnShow={[2.2, 4.2, 6]}
							itemGap={["3xs", "3xs", "3xs"]}
							sx={{
								width: "inherit",
								paddingX: "3xs",
								scrollPadding: "3xs",
								// This was a depcrecated token, but this component is going to be redesigned soon. 🤞
								backgroundColor: "rgba(255, 255, 255, 0.5)",
							}}
							containInParent
						>
							{weather.map(
								({
									highlighted,
									month,
									averageTemperature,
									averageDryDays,
								}) => (
									<div
										key={month}
										data-id="weather"
										sx={{
											padding: "xs",
											flexShrink: 0,
											margin: "4xs",

											backgroundColor: "#fff",
											textAlign: "center",
											...(highlighted && {
												borderBottomWidth: "activeStrokeWeight",
												borderBottomStyle: "solid",
												borderBottomColor: "#11AAF6",
											}),
										}}
									>
										<div
											sx={{
												fontSize: "s",
											}}
										>
											{month}
										</div>
										<div
											sx={{
												fontSize: "xl",
												fontWeight: "bold",
											}}
										>
											{averageTemperature}°C
										</div>
										<div
											sx={{
												fontSize: "xs",
											}}
										>
											{t("weather.averageDryDays", { averageDryDays })}
										</div>
									</div>
								),
							)}
						</Carousel>
					</div>
				</div>
			</div>
		</PageSection>
	);
};
