import { type SetStateAction, createContext, useContext } from "react";

type Selected = string | null;

export const SelectedContext = createContext<Selected>(null);

export function isSelected(current: Selected, key: string | null) {
	return Boolean(key && current === key);
}

export function useIsSelected(key: string) {
	return isSelected(useContext(SelectedContext), key);
}

type DispatchActionEvent<S, E> = (action: SetStateAction<S>, event: E) => void;

export type SetSelected = DispatchActionEvent<
	string | null,
	React.MouseEvent<HTMLElement, MouseEvent>
>;

export const SelectableContext = createContext<SetSelected>(() => {});

export function useToggleSelected(
	key: string,
	onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
) {
	const onSelect = useContext(SelectableContext);

	return (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		onSelect(key, e);

		if (onClick) {
			onClick(e);
		}
	};
}
