import type { ColorsPalette } from "@Themes";
import type { IncrementSizes } from "@Tokens";
import type { SxStyleProp } from "theme-ui";

type Size = keyof Pick<IncrementSizes, "200" | "100" | "64" | "36" | "28">;

const sizeToAssetSize: Record<Size, keyof IncrementSizes> = {
	200: "100",
	100: "48",
	64: "36",
	36: "20",
	28: "16",
};

export interface PictogramProps {
	/**
	 * The size of the asset.
	 */
	size: Size[];

	/**
	 * Color for the rounded background of the asset. Use color token here.
	 */
	backgroundColor?: keyof ColorsPalette;
}

/**
 * Generates styles to render graphics within a rounded, colored background
 */
export const pictogramStyles: (props: PictogramProps) => SxStyleProp = ({
	backgroundColor,
	size,
}) => {
	const svgSize = size.map((s) => sizeToAssetSize[s]);

	const wrapperStyles: SxStyleProp = {
		display: "inline-block",
		backgroundColor,
		textAlign: "center",
		borderRadius: "rounded",
	};

	return {
		...wrapperStyles,
		height: size,
		width: size,
		minHeight: size,
		minWidth: size,
		padding: size.map((s, i) => (Number(s) - Number(svgSize[i])) / 2),
	};
};
