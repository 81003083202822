import { type ComponentProps, Icon } from "@Components";
import type React from "react";
import type { HTMLAttributes } from "react";

import { useIsSelected, useToggleSelected } from "./SunriseUISelectableContext";

export interface ToggleSelectionProps
	extends ComponentProps,
		HTMLAttributes<HTMLElement> {
	controls: string;
	expands?: boolean;
	onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	showChevrons?: boolean;
}

export const SunriseUIToggleSelection: React.FC<ToggleSelectionProps> = ({
	children,
	as: Component = "a",
	controls,
	expands = true,
	onClick,
	showChevrons = false,
	...props
}) => {
	const isSelected = useIsSelected(controls);
	const toggle = useToggleSelected(controls, onClick);

	return (
		<Component
			href={Component === "a" ? `#${controls}` : null}
			aria-controls={controls}
			{...(expands
				? {
						"aria-expanded": isSelected,
					}
				: {})}
			onClick={toggle}
			{...props}
		>
			{children}
			{showChevrons && (
				<Icon
					name={isSelected ? "Toggles/ExpandOff" : "Toggles/ExpandOn"}
					size="20"
					sx={{
						verticalAlign: "middle",
						marginLeft: "3xs",
					}}
				/>
			)}
		</Component>
	);
};
