import { loveholidaysTheme } from "@Themes";
import type React from "react";
import type { ReactElement } from "react";
import type { SxStyleProp } from "theme-ui";

import { DOMInteractionEvent, useTrackingContext } from "@Components";
import { SunriseUIToggleSelection } from "../SunriseUISelectable";
import { getScrollBehavior } from "../utils/getScrollBehavior";

type TabListItemStyle = {
	tabStyles: SxStyleProp;
	titleStyles: SxStyleProp;
};

const verticalTabUnitCSS = (accordion?: boolean): TabListItemStyle => ({
	tabStyles: {
		display: "block",
		color: "textDimmedheavy",
		textDecoration: "none",
		fontSize: accordion ? "l" : ["s", "m"],
		borderStyle: "solid",
		borderColor: "strokeDisabledlight",
		backgroundColor: "white",
		borderBottomColor: "strokeDisabledlight",
		marginRight: 1,

		width: "100%",

		...(!accordion
			? {
					paddingY: "3xs",
					paddingX: ["3xs", "xs"],
					height: "100%",
					borderWidth: "outlinedStrokeWeight",
					borderTopWidth: 0,
				}
			: {}),

		...(accordion
			? {
					display: ["flex", "none"],
					padding: "xs",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
					borderTopWidth: "outlinedStrokeWeight",
				}
			: {}),

		"&[aria-expanded=true]": {
			backgroundColor: "white",
			borderBottomColor: "strokeDisabledlight",
			...(!accordion && {
				borderLeftColor: "tagprimaryDefault",
				borderLeftWidth: "focusStrokeWeight",
				borderRightWidth: 0,
			}),
			...(accordion && {
				borderLeftColor: ["strokeDisabledlight", "transparent"],
				borderTopWidth: ["focusStrokeWeight", 0],
				borderTopColor: "strokeDisabledlight",
			}),
		},

		"&:hover, :visited": {
			color: "textDimmedheavy",
		},
	},
	titleStyles: accordion
		? {
				flex: "1",
			}
		: {},
});

const tabListItemLinkStyles = (
	accordion?: boolean,
	verticalTabUnit?: boolean,
): TabListItemStyle => {
	if (verticalTabUnit) {
		return verticalTabUnitCSS(accordion);
	}
	return {
		tabStyles: {
			flex: "1 1 auto",
			display: "block",
			color: loveholidaysTheme.colors.textLight,
			textDecoration: "none",
			fontSize: accordion ? "l" : ["s", "m"],
			borderStyle: "solid",
			borderColor: loveholidaysTheme.colors.textLight,
			marginRight: 1,

			backgroundColor: loveholidaysTheme.colors.backgroundInformationdark,
			borderBottomColor: loveholidaysTheme.colors.textLight,

			...(!accordion
				? {
						paddingY: "3xs",
						paddingX: ["3xs", "xs"],
						height: "100%",
					}
				: {}),

			...(accordion
				? {
						display: ["block", "none"],
						padding: "xs",
					}
				: {}),

			"&[aria-expanded=true]": {
				backgroundColor: loveholidaysTheme.colors.backgroundPrimary,
				borderBottomColor: loveholidaysTheme.colors.backgroundPrimary,
			},

			"&:hover, :visited": {
				color: loveholidaysTheme.colors.textLight,
			},
		},
		titleStyles: {},
	};
};

function preventDefault<E extends React.SyntheticEvent>(e: E) {
	e.preventDefault();
}

interface SunriseUITabListItemLinkProps {
	id?: string;
	prefix: string;
	tab: ReactElement;
	tabId?: string;
	index: number | string;
	accordion?: boolean;
	verticalTabUnit?: boolean;
	tabLinkStyle?: SxStyleProp;
	onTabChanged?: (tabId: string) => void;
}

export const SunriseUITabListItemLink: React.FC<
	SunriseUITabListItemLinkProps
> = ({
	id,
	prefix,
	index,
	tab,
	tabId,
	accordion,
	verticalTabUnit = false,
	onTabChanged,
	tabLinkStyle = {},
}) => {
	const { trackEvent } = useTrackingContext();

	const controls = `tabpanel_${prefix}_${index}`;

	const handleClick = (e: React.MouseEvent<HTMLElement>) => {
		preventDefault(e);
		const element = document.querySelector(
			`[aria-controls="${controls}"]:not([id])`,
		);
		setTimeout(
			() =>
				element?.scrollIntoView({
					behavior: getScrollBehavior(),
					block: "start",
				}),
			50,
		);

		trackEvent(DOMInteractionEvent.CLICK, prefix, tab.props.title);

		if (onTabChanged && tabId) {
			onTabChanged(tabId);
		}
	};

	const { tabStyles, titleStyles } = tabListItemLinkStyles(
		accordion,
		verticalTabUnit,
	);

	return (
		<SunriseUIToggleSelection
			id={id}
			as="a"
			role="tab"
			controls={controls}
			expands
			onClick={handleClick}
			showChevrons={accordion}
			sx={{ ...tabStyles, ...tabLinkStyle }}
		>
			<span sx={titleStyles}>{tab.props.title}</span>
		</SunriseUIToggleSelection>
	);
};
