import { DontHydrate, type HastNode, hastToReact } from "@AuxiliaryComponents";
import { FlexGrid, Heading, Image, type ImageType, Link } from "@Components";

export interface ContentBoxSectionTabProps {
	featureBlocks: {
		items: {
			content: HastNode;
			heading: string;
			image: ImageType;
		}[];
	};
	heading: string;
}

export const ContentBoxSectionTab: React.FC<ContentBoxSectionTabProps> = (
	topic,
) => (
	<FlexGrid
		columns={[
			1,
			Math.min(2, topic.featureBlocks.items.length),
			Math.min(2, topic.featureBlocks.items.length),
		]}
		gap={["xs", "xs", "xs"]}
	>
		{topic.featureBlocks.items.map(({ image, heading, content }) => (
			<div
				key={`${heading}_${image.url}`}
				sx={{
					boxShadow: "bottom",
					backgroundColor: "white",
				}}
			>
				<Image
					src={image.url}
					alt={image.description}
					width={750}
					height={250}
					fluidHeight="33%"
				/>
				<DontHydrate>
					<article
						sx={{
							padding: "xs",
						}}
					>
						<Heading variant={["medium", "large"]} as="h3">
							{heading}
						</Heading>

						{hastToReact(content, {
							a: {
								Component: Link,
							},
						})}
					</article>
				</DontHydrate>
			</div>
		))}
	</FlexGrid>
);
