import type React from "react";

import {
	DOMInteractionEvent,
	Label,
	Link,
	useTrackingContext,
} from "@Components";
import { type LegacyIcons, svgFilenames } from "@Tokens";

export interface ContentBoxProps {
	title: string;
	description?: string;
	url: string;
	icon: LegacyIcons;
}

export const ContentBox: React.FC<ContentBoxProps> = ({
	title,
	description,
	url,
	icon,
}) => {
	const { trackEvent } = useTrackingContext();

	return (
		<Link
			href={url}
			onClick={() => trackEvent(DOMInteractionEvent.CLICK, "holiday-links")}
			sx={{
				display: "block",
				borderWidth: "outlinedStrokeWeight",
				borderColor: "strokeDarkneutral",
				padding: "l",
				borderRadius: "8px",
				textDecoration: "none",
				color: "textDefault",
				"&:hover, :visited": {
					color: "textDefault",
					"section > p:first-of-type": {
						textDecoration: "underline",
					},
				},
				backgroundColor: "backgroundWhite",
			}}
			aria-label={title}
		>
			<article sx={{ display: "flex", alignItems: "center" }}>
				<span
					sx={{
						height: "56px",
						width: "56px",
						minWidth: "56px",
						minHeight: "56px",
						marginRight: "12px",
						borderRadius: "rounded",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						backgroundColor: "backgroundLightsubtle",
					}}
				>
					<svg
						role="img"
						aria-label={title}
						sx={{
							display: "block",
							height: "30px",
							width: "30px",
							color: "iconDefault",
						}}
					>
						<use href={svgFilenames[`LegacyIcon/${icon}`]} />
					</svg>
				</span>
				<section
					sx={{
						marginRight: "2xs",
						flexGrow: 1,
						display: "flex",
						alignItems: "center",
					}}
				>
					<Label
						variant="largebold"
						sx={{
							color: "textDefault",
						}}
					>
						{title}
					</Label>

					{!!description && (
						<Label
							variant="small"
							sx={{
								marginBottom: "3xs",
								color: "textDimmedmedium",
							}}
						>
							{description}
						</Label>
					)}
				</section>
			</article>
		</Link>
	);
};
