import { createContext, useContext } from "react";

/**
 * To provide unique IDs in Tabs/Tab using tabIndex is not enough.
 * We are passing a "prefix" prop to Tabs, which is accessible by Tab components using this context
 * to be able to generate IDs using this prefix.
 */
export const SunriseUITabsPrefixContext = createContext("");

export const useSunriseUITabsPrefixContext = () =>
	useContext(SunriseUITabsPrefixContext);
