import {
	Heading,
	Image,
	Link,
	Paragraph,
	useTrackingContext,
} from "@Components";
import type React from "react";

export interface DestinationInspirationType {
	customUrl: string | null;
	description: string | null;
	image: {
		description: string;
		url: string;
	};
	searchUrl: string;
	title: string;
}

export interface DestinationInspirationProps
	extends DestinationInspirationType {
	className?: string;
}

export const DestinationInspiration: React.FC<DestinationInspirationProps> = ({
	searchUrl,
	customUrl,
	title,
	image,
	description,
}) => {
	const { sendEvent } = useTrackingContext();

	return (
		<Link
			onClick={() =>
				sendEvent({
					event: "Product Click",
					payload: {
						searchUrl,
						customUrl,
						title,
						image,
						description,
					},
				})
			}
			asAnchor={false}
			internal
			href={customUrl ?? searchUrl}
			data-id="destination-inspiration"
			sx={{
				display: "block",
				textDecoration: "none",
				color: "textDefault",
				"&:hover": {
					color: "textDefault",
					h3: {
						textDecoration: "underline",
					},
					img: {
						transform: "scale(1.04)",
					},
				},
				"&:visited": {
					color: "textDefault",
				},
			}}
		>
			<div
				sx={{
					marginBottom: "2xs",
				}}
			>
				<Image
					lazy
					src={image.url}
					alt={image.description}
					width={[270, 332, 384]}
					height={240}
					sx={{
						borderRadius: "8px",
						overflow: "hidden",
						img: {
							transition: "transform 400ms ease-in-out",
						},
					}}
				/>
			</div>
			<Heading variant="largebold" as="h3" sx={{ marginBottom: "2xs" }}>
				{title}
			</Heading>
			<Paragraph variant="small" sx={{ color: "textDimmedmedium" }}>
				{description}
			</Paragraph>
		</Link>
	);
};
