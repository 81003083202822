import { hastToReact } from "@AuxiliaryComponents";
import {
	FlexGrid,
	Heading,
	LandingBackgroundWrapper,
	PageContainer,
} from "@Components";

import { type LegacyIcons, svgFilenames, viewBoxes } from "@Tokens";
import type React from "react";
import type { HastNode } from "./HastNode";
import { PageSection } from "./PageSection";
import { pictogramStyles } from "./pictogramStyles";

type DestinationUspsSectionComponentProps = {
	destinationUspsSection: {
		title: string;
		destinationUsps: {
			items: {
				content: HastNode;
				heading: string;
				iconName: string;
			}[];
		};
	};
};

export const DestinationUspsSectionComponent: React.FC<
	DestinationUspsSectionComponentProps
> = ({ destinationUspsSection: { title, destinationUsps } }) => {
	return (
		<LandingBackgroundWrapper bgColor="backgroundLightsubtle">
			<PageContainer>
				<PageSection title={title}>
					<FlexGrid columns={[1, 3, 3]} gap={["xs", "xs", "xs"]}>
						{destinationUsps.items.map((usp) => {
							const iconColor = "baseDark";
							const src =
								svgFilenames[`LegacyIcon/${usp.iconName as LegacyIcons}`];
							const viewbox =
								viewBoxes[`LegacyIcon/${usp.iconName as LegacyIcons}`];

							return (
								<div
									key={String(usp.iconName)}
									sx={{
										display: "flex",
										marginBottom: "xs",
									}}
								>
									<div
										sx={{
											color: "iconDefault",
											marginRight: "xs",
										}}
									>
										<svg
											role="img"
											aria-label={title}
											viewBox={`0 0 ${viewbox?.[0]} ${viewbox?.[1]}`}
											sx={{
												...pictogramStyles({
													size: ["64"],
												}),
												color: iconColor,
											}}
										>
											<use href={src} />
										</svg>
									</div>
									<div>
										<Heading variant={["medium", "large"]} as="h3">
											{usp.heading}
										</Heading>

										{hastToReact(usp.content)}
									</div>
								</div>
							);
						})}
					</FlexGrid>
				</PageSection>
			</PageContainer>
		</LandingBackgroundWrapper>
	);
};
