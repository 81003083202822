import { GoogleMap } from "@Components";
import { useInView } from "@Hooks";
import type React from "react";
import { useState } from "react";

interface MapComponentProps {
	mapSection: {
		coordinates: {
			lat: number;
			lon: number;
		};
		zoom: number;
		height: string;
	};
}

export const MapSectionComponent: React.FC<MapComponentProps> = ({
	mapSection: { coordinates, zoom, height },
}) => {
	const [showMap, setShowMap] = useState(false);
	const ref = useInView((isInView) => isInView && setShowMap(true), {
		triggerOnlyOnce: true,
		threshold: 0.1,
	});

	return (
		<div ref={ref} sx={{ height }}>
			{showMap && (
				<GoogleMap
					lat={coordinates.lat}
					lon={coordinates.lon}
					zoom={zoom}
					sx={{ height }}
				/>
			)}
		</div>
	);
};
