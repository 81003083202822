import React, {
	Fragment,
	type ReactElement,
	type FC,
	type HTMLAttributes,
	type ReactNode,
} from "react";
import type { SxStyleProp } from "theme-ui";

import type { ComponentProps } from "@Components";

import { loveholidaysTheme } from "@Themes";
import { SunriseUISelection } from "../SunriseUISelectable";
import type { SunriseUITabProps } from "./SunriseUITab";
import { SunriseUITabListItemLink } from "./SunriseUITabListItemLink";
import { SunriseUITabsPrefixContext } from "./SunriseUITabsPrefixContext";

interface WrapperProps extends HTMLAttributes<HTMLDivElement> {
	children?: ReactNode;
}

export interface SunriseUITabsProps extends ComponentProps {
	prefix: string;
	selectedIndex?: number | string;
	children: (ReactElement<SunriseUITabProps> | null | boolean)[];
	shouldCollapse?: boolean;
	tabStyles?: SxStyleProp;
	tabLinkStyle?: SxStyleProp;
	id?: string;
	tabContainerStyles?: SxStyleProp;
	onTabChanged?: (tabId: string) => void;
}

export const SunriseUITabs: FC<SunriseUITabsProps> = ({
	"data-id": dataId,
	prefix,
	shouldCollapse = true,
	selectedIndex = 0,
	children,
	className,
	tabStyles = {},
	tabLinkStyle = {},
	tabContainerStyles = {},
	onTabChanged,
}) => {
	if (!React.Children.count(children)) {
		throw new Error("You must have some children");
	}

	const tabs = React.Children.toArray(children).filter(
		Boolean,
	) as ReactElement<SunriseUITabProps>[];

	const displayStyle = shouldCollapse ? ["none", "flex"] : "flex";

	return (
		<SunriseUITabsPrefixContext.Provider value={prefix}>
			<div data-id={dataId} className={className}>
				<SunriseUISelection
					alwaysSelected
					selection={`tabpanel_${prefix}_${selectedIndex}`}
				>
					<ul
						role="tablist"
						sx={{
							display: displayStyle,
							paddingX: "3xs",
							flexWrap: "wrap",
							borderBottomWidth: shouldCollapse
								? [0, "focusStrokeWeight"]
								: "focusStrokeWeight",
							borderBottomStyle: "solid",
							borderBottomColor: loveholidaysTheme.colors.backgroundPrimary,
							...tabContainerStyles,
						}}
					>
						{tabs.map((tab, i) => (
							<li
								role="presentation"
								key={`${tab.props.title}-${i}`}
								sx={{
									display: "flex",
									flex: ["1", "0 0 auto"],
									...tabStyles,
								}}
							>
								<SunriseUITabListItemLink
									id={`tab_${prefix}_${i}`}
									prefix={prefix}
									tab={tab}
									tabId={tab.props.id}
									index={tab.props.index ?? i}
									onTabChanged={onTabChanged}
									tabLinkStyle={tabLinkStyle}
								/>
							</li>
						))}
					</ul>

					{tabs.map((tab, i) => {
						const clonedTab = React.cloneElement(tab, {
							index: tab.props.index ?? i,
						});
						const Wrapper = shouldCollapse
							? (props: WrapperProps) => <div role="tablist" {...props} />
							: Fragment;

						return (
							<Wrapper key={`${tab.props.title}-${i}`}>
								{shouldCollapse && (
									<SunriseUITabListItemLink
										prefix={prefix}
										tab={tab}
										index={tab.props.index ?? i}
										accordion
									/>
								)}
								{clonedTab}
							</Wrapper>
						);
					})}
				</SunriseUISelection>
			</div>
		</SunriseUITabsPrefixContext.Provider>
	);
};
