import { LandingBackgroundWrapper } from "@Components";
import { PageContainer } from "@Components";
import type React from "react";
import { SunriseUITab } from "sharedPageComponents/SunriseUITabs/SunriseUITab";
import {
	ContentBoxSectionTab,
	type ContentBoxSectionTabProps,
} from "./ContentBoxSectionTab";
import { PageSection } from "./PageSection";
import { SunriseUITabs } from "./SunriseUITabs";

interface ContentBoxesSectionComponentProps {
	contentBoxesSection: {
		contentBlocks: {
			items: ContentBoxSectionTabProps[];
		};
		title: string;
	};
}

export const ContentBoxesSectionComponent: React.FC<
	ContentBoxesSectionComponentProps
> = ({ contentBoxesSection: { contentBlocks, title } }) => {
	return (
		<LandingBackgroundWrapper bgColor="backgroundLightsubtle">
			<PageContainer>
				<PageSection title={title}>
					{contentBlocks.items.length === 1 ? (
						<ContentBoxSectionTab {...contentBlocks.items[0]} />
					) : (
						<SunriseUITabs
							prefix="content-boxes-sections"
							tabStyles={{
								flex: [1, "1 0 auto"],
								textAlign: "center",
							}}
						>
							{contentBlocks.items.map((topic) => (
								<SunriseUITab key={topic.heading} title={topic.heading}>
									<ContentBoxSectionTab {...topic} />
								</SunriseUITab>
							))}
						</SunriseUITabs>
					)}
				</PageSection>
			</PageContainer>
		</LandingBackgroundWrapper>
	);
};
