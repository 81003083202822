import type React from "react";
import type { FC, PropsWithChildren } from "react";

import type { ComponentProps } from "@Components";

import { SelectableCollapse } from "../SunriseUICollapse";
import { useSunriseUITabsPrefixContext } from "./SunriseUITabsPrefixContext";

export interface SunriseUITabProps extends PropsWithChildren<ComponentProps> {
	index?: number | string;
	title: string;
	lazy?: boolean;
	children: React.ReactNode;
}

export const SunriseUITab: FC<SunriseUITabProps> = ({
	children,
	className,
	index,
	lazy,
}) => {
	const prefix = useSunriseUITabsPrefixContext();

	return (
		<SelectableCollapse
			id={`tabpanel_${prefix}_${index}`}
			role="tabpanel"
			aria-labelledby={`tab_${prefix}_${index}`}
			lazy={lazy}
			className={className}
		>
			{children}
		</SelectableCollapse>
	);
};
