import { Heading, PageContainer } from "@Components";
import { useInViewTracking } from "@Hooks";
import { Carousel } from "@Modules";
import { useTranslation } from "@loveholidays/phrasebook";
import type React from "react";

import {
	DestinationInspiration,
	type DestinationInspirationType,
} from "./DestinationInspirationComponent";

interface DestinationInspirationsProps {
	destinationInspirations: DestinationInspirationType[];
}

export const DestinationInspirationsComponent: React.FC<
	DestinationInspirationsProps
> = ({ destinationInspirations }) => {
	const { t } = useTranslation();

	const impressionRef = useInViewTracking(
		{
			event: "Product List Viewed",
			payload: {
				list: "PopularHolidays",
			},
		},
		!destinationInspirations?.length,
	);

	if (!destinationInspirations?.length) {
		return null;
	}

	return (
		<section
			ref={impressionRef}
			data-id="destination-inspirations"
			sx={{
				backgroundColor: "backgroundLightsubtle",
				paddingTop: "l",
				paddingBottom: "l",
			}}
		>
			<PageContainer>
				<Heading variant="largebold" as="h2" sx={{ marginBottom: "s" }}>
					{t("popularHolidays")}
				</Heading>
				<Carousel>
					{destinationInspirations.map((item) => (
						<DestinationInspiration key={item.searchUrl} {...item} />
					))}
				</Carousel>
			</PageContainer>
		</section>
	);
};
