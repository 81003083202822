import { type Effects, effects } from "@Tokens";
import type { ShadowsPalette } from "../types";

export const effectTokens = Object.entries(effects).reduce(
	(prev, [name, values]) => ({
		...prev,
		[name]: `${values.offsetX} ${values.offsetY} ${values.radius} ${values.spread} ${values.color}`,
	}),
	{},
) as Effects;

export const shadows: ShadowsPalette = effectTokens;
