import {
	borders as borderWidths,
	breakpointSizes,
	motion,
	radii,
	incrementSizes as sizes,
	spacerSizes as space,
	zIndices,
} from "@Tokens";
import type { ThemeDefinition } from "../types";
import { colors } from "./colors";
import { fontFaces, fonts } from "./fonts";
import { shadows } from "./shadows";
import { typographyVariants as typography } from "./typography";

export const theme: ThemeDefinition = {
	useCustomProperties: false,
	breakpoints: ["768px", "1200px"],
	breakpointSizes,
	radii,
	borderWidths,
	colors,
	fonts,
	fontFaces,
	shadows,
	space,
	sizes,
	typography,
	motion,
	zIndices,
};
