import type { SpacePalette } from "@Themes";
import type { SpacerSizes } from "@Tokens";
import { getFlexBasis, getGap } from "@Utils";
import type React from "react";

export interface FlexGridProps {
	columns: [number, number, number];
	gap: [keyof SpacePalette, keyof SpacePalette, keyof SpacePalette];
	horizontalOnMobile?: boolean;
	children: React.ReactNode;
	className?: string;
}

/**
 * Component to layout children in a grid like format where each child has equal width and a specified gap between them
 * eg: if number of columns is 3 and there are 5 items it will layout the children like so:
 *
 * X X X
 *
 * X X
 *
 */
export const FlexGrid: React.FC<FlexGridProps> = ({
	columns,
	gap: gapRaw,
	children,
	className,
	horizontalOnMobile,
}) => {
	// Fix typings, as `SpacerSizes` does not contain the pre-DS tokens we have in `SpacePalette`
	const gap = gapRaw as (keyof SpacerSizes)[];

	return (
		<div
			className={className}
			sx={{
				display: "flex",
				flexDirection: "row",
				flexWrap: horizontalOnMobile ? ["nowrap", "wrap"] : "wrap",
				overflowX: horizontalOnMobile ? "auto" : null,
				marginX: (t) => [
					`-${getGap(columns[0], gap[0], t) / 2}px`,
					`-${getGap(columns[1], gap[1], t) / 2}px`,
					`-${getGap(columns[2], gap[2], t) / 2}px`,
				],
				"> *": {
					flexBasis: [
						horizontalOnMobile ? null : getFlexBasis(columns[0], gap[0]),
						getFlexBasis(columns[1], gap[1]),
						getFlexBasis(columns[2], gap[2]),
					],
					flexGrow: 0,
					flexShrink: 0,
					marginLeft: (t) => [
						`${getGap(columns[0], gap[0], t) / 2}px`,
						`${getGap(columns[1], gap[1], t) / 2}px`,
						`${getGap(columns[2], gap[2], t) / 2}px`,
					],
					marginRight: (t) => [
						horizontalOnMobile
							? "3xs"
							: `${getGap(columns[0], gap[0], t) / 2}px`,
						`${getGap(columns[1], gap[1], t) / 2}px`,
						`${getGap(columns[2], gap[2], t) / 2}px`,
					],
				},
			}}
		>
			{children}
		</div>
	);
};
