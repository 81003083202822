import React, { Fragment, type ReactElement } from "react";
import { SunriseUISelection } from "sharedPageComponents/SunriseUISelectable";
import type { SxStyleProp } from "theme-ui";
import type { SunriseUITabProps } from "./SunriseUITab";
import { SunriseUITabListItemLink } from "./SunriseUITabListItemLink";
import type { SunriseUITabsProps } from "./SunriseUITabs";
import { SunriseUITabsPrefixContext } from "./SunriseUITabsPrefixContext";

const collapsedStyle = (
	shouldCollapse: boolean,
	style: string | number,
	collapsedStyle: string | number | null = null,
) => (shouldCollapse ? [collapsedStyle, style] : style) as SxStyleProp;

export const SunriseUIVerticalTabs: React.FC<SunriseUITabsProps> = ({
	prefix,
	shouldCollapse = true,
	selectedIndex = 0,
	children,
	className,
	tabStyles = {},
}) => {
	if (!React.Children.count(children)) {
		throw new Error("You must have some children");
	}

	const tabs = React.Children.toArray(children).filter(
		Boolean,
	) as ReactElement<SunriseUITabProps>[];

	return (
		<SunriseUITabsPrefixContext.Provider value={prefix}>
			<div
				className={className}
				sx={{
					display: "flex",
					flexDirection: "row",
				}}
			>
				<SunriseUISelection
					alwaysSelected
					selection={`tabpanel_${prefix}_${selectedIndex}`}
				>
					<div
						sx={{
							display: collapsedStyle(shouldCollapse, "flex", "none"),
							flexBasis: collapsedStyle(shouldCollapse, "33%"),
							flexDirection: collapsedStyle(shouldCollapse, "column"),
						}}
					>
						<ul
							role="tablist"
							sx={{
								display: "flex",
								borderStyle: collapsedStyle(shouldCollapse, "solid"),
								borderWidth: collapsedStyle(shouldCollapse, "1px 0 0 0"),
								borderColor: collapsedStyle(
									shouldCollapse,
									"strokeDisabledlight",
								),
								flexWrap: "wrap",
							}}
						>
							{tabs.map((tab, i) => (
								<li
									role="presentation"
									key={`${tab.props.title}-${i}`}
									sx={{
										flex: collapsedStyle(shouldCollapse, "1 1 100%"),
										position: "relative", // Safari fix where only text is clickable with img behind
										...tabStyles,
									}}
								>
									<SunriseUITabListItemLink
										id={`tab_${prefix}_${i}`}
										prefix={prefix}
										tab={tab}
										index={i}
										verticalTabUnit
									/>
								</li>
							))}
						</ul>
						<div
							sx={{
								display: collapsedStyle(shouldCollapse, "flex", "none"),
								flex: collapsedStyle(shouldCollapse, 1),
								borderStyle: collapsedStyle(shouldCollapse, "solid"),
								borderWidth: collapsedStyle(shouldCollapse, "0 1px 0 0"),
								borderColor: collapsedStyle(
									shouldCollapse,
									"strokeDisabledlight",
								),
							}}
						/>
					</div>
					<div
						sx={{
							flexBasis: collapsedStyle(shouldCollapse, "67%"),
							borderColor: collapsedStyle(
								shouldCollapse,
								"strokeDisabledlight",
							),
							borderStyle: collapsedStyle(shouldCollapse, "solid"),
							borderWidth: collapsedStyle(shouldCollapse, "1px 1px 1px 0"),
							padding: collapsedStyle(shouldCollapse, "xl"),
						}}
					>
						{tabs.map((tab, i) => {
							const clonedTab = React.cloneElement(tab, {
								index: i,
							});
							const Wrapper = shouldCollapse
								? (props: React.ComponentProps<"div">) => (
										<div
											role="tablist"
											{...props}
											sx={{
												borderStyle: ["solid", "none"],
												borderColor: ["strokeDisabledlight", "none"],
												borderWidth: ["0 1px 1px", "0"],
												marginBottom: ["3xs", "0"],
											}}
										/>
									)
								: Fragment;

							return (
								<Wrapper key={`${tab.props.title}-${i}`}>
									{shouldCollapse && (
										<SunriseUITabListItemLink
											prefix={prefix}
											tab={tab}
											index={i}
											accordion
											verticalTabUnit
										/>
									)}
									{clonedTab}
								</Wrapper>
							);
						})}
					</div>
				</SunriseUISelection>
			</div>
		</SunriseUITabsPrefixContext.Provider>
	);
};
