import { Icon, Label, Tooltip } from "@Components";
import type React from "react";

type DiscountMerchandisingProps = {
	copy: string;
};

const DiscountMerchandisingMessagingContent: React.FC<
	DiscountMerchandisingProps
> = ({ copy }) => (
	<Label as="p" variant="small" sx={{ maxWidth: "300px" }}>
		{copy}
	</Label>
);

export const DiscountMerchandisingMessagingTooltip: React.FC<
	DiscountMerchandisingProps
> = ({ copy }) => (
	<Tooltip
		content={<DiscountMerchandisingMessagingContent copy={copy} />}
		sx={{
			display: "flex",
			alignItems: "center",
			marginLeft: "3xs",
		}}
	>
		<Icon size="16" name="Markers/Tooltip" />
	</Tooltip>
);
