import type React from "react";

import type { ColorsPalette } from "@Themes";

export interface LandingBackgroundWrapperProps {
	bgColor?: Extract<
		keyof ColorsPalette,
		"white" | "landingPageBackgroundWrapper" | "backgroundLightsubtle"
	>;
	className?: string;
	children: React.ReactNode;
}
export const LandingBackgroundWrapper: React.FC<
	LandingBackgroundWrapperProps
> = ({ className, children, bgColor = "white" }) => (
	<div
		className={className}
		sx={{
			paddingY: ["3xl"],
			width: "100%",
			backgroundColor: bgColor,
		}}
	>
		{children}
	</div>
);
