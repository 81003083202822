import { useRef } from "react";

import { useWindowSize } from "@Providers/WindowSizeProvider";

const isClient = typeof window !== "undefined";

/**
 * Returns the innerHeight of the window in pixels.
 * - Client side in pixels eg: `659px`
 * - Server side always returns `100dvh`
 *
 * The name is a misnomer. It should be `use100dvh`.
 *
 * It is necessary for us to use Javascript for this, as we currently support older browsers that do
 * not support the `dvh` unit. For this reason, components that use this should always try and
 * hydrate to avoid the server side fallback.
 */
export const use100vh = () => {
	if (!isClient) {
		return "100dvh";
	}
	const ref = useRef("");

	const { height } = useWindowSize();
	ref.current = `${height}px`;

	return ref.current;
};
