import { DontHydrate } from "@AuxiliaryComponents";
import { Paragraph, Title, pageContainerStyles } from "@Components";
import { HeroImage } from "@Modules";
import { useTranslation } from "@loveholidays/phrasebook";

interface HeroBannerComponentType {
	heroBanner: {
		displayCheapestPrice: string | null;
		showSearchForm: boolean;
		showMainImage: boolean;
		image: {
			url: string;
			description: string;
		} | null;
		title: string;
		searchConfiguration: {
			showDestinationInput: boolean;
			earliestHotelOnlySalesDate: string | null;
		};
	};
}

export const HeroBannerComponent: React.FC<HeroBannerComponentType> = ({
	heroBanner: {
		showMainImage,
		image,
		title,
		displayCheapestPrice,
		showSearchForm,
	},
}) => {
	const { t } = useTranslation();

	return (
		<div
			sx={{
				display: "flex",
				flexDirection: ["column", "column-reverse"],
			}}
		>
			{/* Have this load in dynamically if the conditions are met */}
			{showMainImage && image && (
				<DontHydrate>
					<div
						sx={{
							marginTop: showSearchForm ? null : ["s", 0],
							overflow: "hidden",
							position: "relative",
							...pageContainerStyles,
						}}
					>
						<HeroImage
							image={image}
							sx={{
								width: "100%",
								objectFit: "cover",
								overflow: "hidden",
								margin: "0 auto",
								borderRadius: "12",
							}}
							height={[150, 300, 380]}
							widths={[400, 810, 1280]}
							dpr={1}
						/>
						<div
							sx={{
								position: "absolute",
								top: 0,
								left: 0,
								right: 0,
								padding: "l",
								paddingBottom: ["xl", "4xl"],
								borderRadius: "12",
								backgroundImage:
									"linear-gradient(180deg, rgba(0, 0, 0, 0.4) 47%, transparent 100%)",
							}}
						>
							{title && (
								<Title
									variant="small"
									as="h1"
									sx={{
										color: "white",
										filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24))",
									}}
								>
									{title}
								</Title>
							)}
							{displayCheapestPrice && (
								<Paragraph
									variant="large"
									sx={{
										color: "white",
										filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24))",
									}}
								>
									{`${t("priceQuoteUnit.from").toLowerCase()} `}
									<span sx={{ fontWeight: "bold" }}>
										{displayCheapestPrice} {t("pricesShownAre.perPerson")}
									</span>
								</Paragraph>
							)}
						</div>
					</div>
				</DontHydrate>
			)}
		</div>
	);
};
