import { MultiPromotion } from "@Modules";
import type { Icons } from "@Tokens";
import type { HastNode } from "./HastNode";

type PromoCampaignHeaderSection = {
	text: null | HastNode;
	color: null | string;
};

type MultiPromotionComponentType = {
	campaigns: {
		url: string | null;
		appUrl: string | null;
		header: {
			title: {
				text: HastNode | null;
				color: string | null;
			} | null;
			subtitle: {
				text: HastNode | null;
				color: string | null;
			} | null;
			cta: {
				text: HastNode | null;
				color: string | null;
			} | null;
		} | null;
		footer: {
			left: {
				icon: Icons | null;
				variant: string;
				text: string | null;
				color: string | null;
			} | null;
			right: {
				image: {
					url: string;
					description: string;
				} | null;
				icon: Icons | null;
				color: string | null;
				size: string | null;
			} | null;
		} | null;
		background: {
			image: {
				url: string;
				description: string;
			} | null;
			color: string | null;
			overlayType: string;
		} | null;
		foregroundImage: {
			url: string;
			description: string;
		} | null;
	}[];
};

export const MultiPromotionComponent: React.FC<MultiPromotionComponentType> = ({
	campaigns,
}) => {
	return (
		<MultiPromotion
			campaigns={campaigns}
			imageSettings={{ quality: 70, dpr: 0.75 }}
		/>
	);
};
