import { Caption, Label } from "@Components";
import { useTranslation } from "@loveholidays/phrasebook";
import type { SystemStyleObject } from "@styled-system/css";
import { type FC, Fragment } from "react";

import { DiscountTooltip } from "./DiscountTooltip";
import type { Pricing } from "./type";

interface PriceProps {
	pricing: Pricing;
	strikeThroughStyles?: SystemStyleObject;
	priceQuoteUnit: "PER_PERSON" | "TOTAL";
}

const DiscountPercentage: FC<{ discountPercentage: string }> = ({
	discountPercentage,
}) => (
	<Caption
		variant="small"
		sx={{
			fontWeight: "bold",
			color: "actioncriticalDefault",
			marginRight: "4xs",
		}}
	>
		{discountPercentage}
	</Caption>
);

interface PricesCaptionProps {
	priceQuoteUnit: "PER_PERSON" | "TOTAL";
	wasPerPerson: string;
	wasTotal: string;
}

const PricesCaption: FC<PricesCaptionProps> = ({
	priceQuoteUnit,
	wasPerPerson,
	wasTotal,
}) => {
	const { t } = useTranslation();

	return (
		<Caption variant="strikethrough" data-id="prices-previous">
			<span
				sx={{
					color: "textDimmedheavy",
					whiteSpace: "nowrap",
				}}
			>
				{priceQuoteUnit === "PER_PERSON" ? (
					<Fragment>
						{wasPerPerson} {t("priceQuoteUnit.perPersonShort")}
					</Fragment>
				) : (
					wasTotal
				)}
			</span>
		</Caption>
	);
};

interface PricesLabelProps {
	priceQuoteUnit: "PER_PERSON" | "TOTAL";
	textPerPerson?: string;
	textTotal: string;
}

const PricesLabel: FC<PricesLabelProps> = ({
	priceQuoteUnit,
	textPerPerson,
	textTotal,
}) => {
	const { t } = useTranslation();

	return (
		<Label
			variant="extralargebold"
			data-id="prices-total"
			sx={{
				whiteSpace: "nowrap",
			}}
		>
			{priceQuoteUnit === "PER_PERSON" && textPerPerson ? (
				<Fragment>
					{textPerPerson}
					<span sx={{ fontSize: "m", marginLeft: "4xs", lineHeight: 1 }}>
						{t("priceQuoteUnit.perPersonShort")}
					</span>
				</Fragment>
			) : (
				textTotal
			)}
		</Label>
	);
};

export const Prices: FC<PriceProps> = ({
	pricing,
	strikeThroughStyles,
	priceQuoteUnit,
}) => {
	const { t } = useTranslation();

	return (
		<Fragment>
			{pricing.pricingPresentation.showStrikeThrough && (
				<div
					sx={{
						display: "flex",
						...strikeThroughStyles,
					}}
				>
					{pricing.pricingPresentation.discountPercentage && (
						<DiscountPercentage
							discountPercentage={
								pricing.pricingPresentation.discountPercentage
							}
						/>
					)}
					<PricesCaption
						priceQuoteUnit={priceQuoteUnit}
						wasPerPerson={pricing.pricingPresentation?.wasPerPerson}
						wasTotal={pricing.pricingPresentation?.wasTotal}
					/>
					<DiscountTooltip pricing={pricing} />
				</div>
			)}
			<PricesLabel
				priceQuoteUnit={priceQuoteUnit}
				textPerPerson={pricing.pricingPresentation?.textPerPerson}
				textTotal={pricing.pricingPresentation?.textTotal}
			/>
		</Fragment>
	);
};
